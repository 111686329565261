<template>
  <div>
    <van-row style="line-height:15px">
      <span style="font-size:15px;font-weight:bold">新闻速报</span>
    </van-row>
    <van-steps :active="data.length" active-color="#317cff" direction="vertical">
       <van-step v-for="item, idx in data" :key="idx">
          <van-cell :title="item.title" :value="item.pubDateStr" @click="toSource(item)" is-link />
       </van-step>
    </van-steps>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toSource(item) {
      window.open(item.sourceUrl, "_blank")
    }
  }
}
</script>

<style>

</style>