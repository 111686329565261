<template>
  <div>
    <table style="width:100%;text-align:center" cellspacing="0">
      <tr style="height:25px">
        <th>地区</th>
        <th>新增确诊</th>
        <th>累计确诊</th>
        <th>治愈</th>
        <th v-if="!isChild">区域风险</th>
        <th v-else>死亡</th>
        <th></th>
      </tr>
      <tr v-for="area, pidx in data" :key="'p'+pidx" @click="rowClick(area)">
        <td>{{area.name}}</td>
        <td>{{area.today.confirm}}</td>
        <td>{{area.total.confirm}}</td>
        <td>{{area.total.heal}}</td>
        <td v-if="!isChild">{{area.today.risk_level == 0? '全部低风险': area.today.risk_level == 1? '部分中风险': '部分高风险'}}</td>
        <td v-else>{{area.total.dead}}</td>
        <td><img src="https://mat1.gtimg.com/news/feiyanarea/arrow_right.png" alt="" style="height:15px" v-if="!isChild"></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isChild: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    rowClick(area) {
      if(!this.isChild) {
        this.$router.push(`/yiqing/area?province=${area.name}`)
      }
    }
  }
}
</script>

<style scoped>
th {
  font-size: 13px;
  font-weight: bold;
  height: 40px;
  color: #4180f1;
  background: #eef4ff;
}
tr {
  height: 40px;
  font-size: 13px;
}
td {
  border-bottom: 1px solid #f1f1f1;
}
table {
  font-size: 12px;
  border: 0;
}
</style>