<template>
  <div>
    <van-row style="text-align:center">
      <van-col span="24" style="line-height:15px">
        <span class="badge-txt">较上日</span><span class="badge" :style="{'color': color}">{{badge >= 0? `+${badge}`:badge}}</span>
      </van-col>
      <van-col span="24" style="padding-top:5px">
        <span :style="{'color': color, 'font-size': value >= 10000? '20px':'24px'}">{{value}}</span>
      </van-col>
      <van-col span="24" style="line-height:15px">
        <span class="title">
          {{title}}
        </span>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: ""
    },
    bgColor: {
      type: String,
      default: ""
    },
    badge: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    },

  }
}
</script>

<style scoped>
.badge-txt {
  font-size: 10px;
  color: #7c7c7c;
}
.badge {
  font-size: 10px;
}
.title {
  font-size: 12px;
  line-height: 15px;
}
</style>